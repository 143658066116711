<template>
    <header v-show="!isHome || !$isMobile()">
        <div class="d-flex justify-content-center pt-2">
            <img src="@/assets/img/logo-white.svg" />
        </div>
    </header>
</template>

<script>
export default {
    name: "Header",
    props: {
        Titulo: {
            type: String,
            default: "default"
        }
    },
    computed: {
        isHome() {
            return this.$route.path === "/"
        }
    },
}
</script>
