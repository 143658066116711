import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

let analytics;

if (process.env.NODE_ENV === 'production') {
    const firebaseConfig = {
        apiKey: "AIzaSyB5Fh_UNib6dpC0iUU7MDJUDj0ftl88_YY",
        authDomain: "firmatech-8e606.firebaseapp.com",
        projectId: "firmatech-8e606",
        storageBucket: "firmatech-8e606.appspot.com",
        messagingSenderId: "61070810425",
        appId: "1:61070810425:web:9e3dc0715f84e1a97e1ce8",
        measurementId: "G-NV0H3M9XX3"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    analytics = getAnalytics(app);
}

export { analytics };